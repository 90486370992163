import React, { Component } from 'react'

class LogOut extends Component {
  componentDidMount() {
    window.location.href = '/Home/Logout'
  }

  render() {
    return (
      <div>
        <h2>Logging you out of Lighthouse...</h2>
      </div>
    )
  }
}

export default LogOut
