import React, { Component } from 'react'
import PropTypes from 'prop-types'

class AppError extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.history.push({
        pathname: '/'
      })
    }, 2000)
  }

  render() {
    return (
      <div>
        <h1>Error</h1>
        <p>An error occured while processing your request !</p>
        <p>You will be Redirect to &quot;Home Page&quot; in two seconds...</p>
      </div>
    )
  }
}
AppError.propTypes = {
  history: PropTypes.object.isRequired
}
export default AppError
