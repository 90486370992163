import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import InputLabel from '@material-ui/core/InputLabel'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'

import SearchPeople from '../Share/SearchPeople'
import RoleComponent from './RoleComponent'
import CategoryComponent from './CategoryComponent'
import DepartmentComponent from './DepartmentComponent'
import ApiServiceCall from '../../Service/ApiServiceCall'

import './FindPeople.css'

class FindPeople extends Component {
  constructor(props) {
    super(props)
    this.state = {
      targetNetId: '',
      targetUcsbCampusId: '',
      targetUserMail: '',
      targetFirstName: '',
      targetLastName: '',
      appError: false,
      rolesDetails: this.props.location.state.roleData,
      roleCode: 2,
      reasonInput: '',
      selectedCategoryList: [],
      fullDepartmentList: [],
      leftdepartmentList: [],
      rightdepartmentList: [],
      selectedDepartmentList: [],
      isErrorDialogOpen: false,
      dialogMessage: ''
    }
    this.onSearchPeopleChange = this.onSearchPeopleChange.bind(this)
    this.onRoleChange = this.onRoleChange.bind(this)
    this.onFindPeopleCategoryChange = this.onFindPeopleCategoryChange.bind(this)
    this.onFindPeopleDepartmentChange = this.onFindPeopleDepartmentChange.bind(
      this
    )
  }

  componentDidMount() {
    console.log(`componentDidMount Call`)
    this.fetchDepartment()
  }

  render() {
    const buttonStyle = {
      color: '#003660',
      backgroundColor: '#C9BF9D',
      fontWeight: 'bold'
    }
    const buttonDisableStyle = {
      color: '#9ea0a1',
      backgroundColor: '#e6d5c8',
      fontWeight: 'bold'
    }
    const buttonDialogStyle = {
      color: 'white',
      backgroundColor: 'darkslateblue'
    }
    const roleId = this.state.roleCode

    let categoryList
    let multiDepartmentList
    const warningDialouge = (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={this.state.isErrorDialogOpen}
        keepMounted
      >
        <DialogTitle className="dialog-title" id="confirmation-dialog-title">
          Permission Alert for User: {this.state.targetFirstName}{' '}
          {this.state.targetLastName}
        </DialogTitle>
        <DialogContent className="dialog-content content-height" dividers>
          <b>The permission(s) below can not be added:</b>
          <br />
          <br />
          {this.state.dialogMessage}
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button style={buttonDialogStyle} onClick={this.onDialogOK}>
            Ok, these permissions have been skipped
          </Button>
        </DialogActions>
      </Dialog>
    )
    if (roleId === 1) {
      multiDepartmentList = (
        <div className="alert alert-dismissible alert-warning">
          <strong className="alert-heading">Warning!</strong>
          <p>The administrator role does not require department.</p>
        </div>
      )
      categoryList = (
        <div className="alert alert-dismissible alert-warning">
          <strong className="alert-heading">Warning!</strong>
          <p>The administrator role does not require category.</p>
        </div>
      )
    } else if (roleId === 2) {
      multiDepartmentList = <React.Fragment></React.Fragment>
      categoryList = (
        <React.Fragment>
          <InputLabel>Select a Data Category</InputLabel>
          <Divider />
          <CategoryComponent
            userRole={roleId}
            onCategoryCheckChange={this.onFindPeopleCategoryChange}
          />
        </React.Fragment>
      )
    } else {
      multiDepartmentList = (
        <React.Fragment>
          <InputLabel>Department</InputLabel>
          <Divider />
          <DepartmentComponent
            leftListItems={this.state.leftdepartmentList}
            rightListItems={this.state.rightdepartmentList}
            onTranferClickItems={this.onFindPeopleDepartmentChange}
          />
        </React.Fragment>
      )
      categoryList = (
        <React.Fragment>
          <InputLabel>Select a Data Category</InputLabel>
          <Divider />
          <CategoryComponent
            userRole={roleId}
            onCategoryCheckChange={this.onFindPeopleCategoryChange}
          />
        </React.Fragment>
      )
    }
    return (
      <Container maxWidth="xs">
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginBottom: '1em' }}>
            {warningDialouge}
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '1em' }}>
            <h2 id="tabelLabel">Assign Permission Authorizations</h2>
            <SearchPeople onSearchPeople={this.onSearchPeopleChange} />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '1em' }}>
            <RoleComponent
              onGetRole={this.onRoleChange}
              userAccesType={this.props.userAccesType}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '1em' }}>
            {multiDepartmentList}
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '1em' }}>
            {categoryList}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <TextField
                required
                id="reason-required"
                label="Provide reason for this permission assignment"
                value={this.state.reasonInput}
                onChange={this.onReasonChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              onClick={this.onAssignPermissionButtonClick}
              style={
                this.state.reasonInput === '' ||
                this.state.targetNetId === '' ||
                this.state.selectedCategoryList.length === 0 ||
                this.state.selectedDepartmentList.length === 0
                  ? buttonDisableStyle
                  : buttonStyle
              }
              disabled={
                !!(
                  this.state.reasonInput === '' ||
                  this.state.targetNetId === '' ||
                  this.state.selectedCategoryList.length === 0 ||
                  this.state.selectedDepartmentList.length === 0
                )
              }
            >
              Assign Permission
            </Button>
          </Grid>
        </Grid>
      </Container>
    )
  }

  onDialogCancel = e => {
    this.setState({
      isErrorDialogOpen: false,
      dialogMessage: ''
    })
  }

  onDialogOK = e => {
    this.setState({
      isErrorDialogOpen: false,
      dialogMessage: ''
    })
    this.props.history.push('/dashboard')
  }

  onSearchPeopleChange(
    targetNetId,
    targetUcsbCampusId,
    targetUserMail,
    targetFirstName,
    targetLastName,
    appError
  ) {
    if (appError === true) {
      this.props.history.push({
        pathname: '/error'
      })
    }
    this.setState({
      targetNetId,
      targetUcsbCampusId,
      targetUserMail,
      targetFirstName,
      targetLastName
    })
  }

  onRoleChange(selectedRole) {
    if (selectedRole.roleId === -100) {
      this.props.history.push({
        pathname: '/log-out'
      })
    } else {
      this.setState({
        roleCode: selectedRole.roleId,
        selectedCategoryList: [],
        leftdepartmentList: [],
        rightdepartmentList: [],
        selectedDepartmentList: []
      })
      this.setDepartmentList(selectedRole.roleId)
    }
  }

  onFindPeopleCategoryChange(checkedCategory) {
    const selectedCategoryIds = []
    for (let i = 0; i < checkedCategory.length; i++) {
      const currentIndex = selectedCategoryIds.findIndex(
        item => item.controlId === checkedCategory[i].treeId
      )
      if (currentIndex === -1) {
        selectedCategoryIds.push({
          controlId: parseInt(checkedCategory[i].treeId),
          controlName: checkedCategory[i].treeName
        })
      } else {
        selectedCategoryIds.splice(currentIndex, 1)
      }
    }
    this.setState({
      selectedCategoryList: selectedCategoryIds
    })
  }

  onFindPeopleDepartmentChange(right) {
    const selectedDepartmentIds = this.state.selectedDepartmentList
    for (let i = 0; i < right.length; i++) {
      const currentIndex = selectedDepartmentIds.findIndex(
        item => item.controlId === right[i].controlId
      )
      if (currentIndex === -1) {
        selectedDepartmentIds.push({
          controlId: parseInt(right[i].controlId),
          controlName: right[i].controlName
        })
      } else {
        selectedDepartmentIds.splice(currentIndex, 1)
      }
    }
    this.setState({
      selectedDepartmentList: selectedDepartmentIds
    })
  }

  onReasonChange = async e => {
    this.setState({
      reasonInput: e.target.value
    })
  }

  onAssignPermissionButtonClick = async () => {
    const newPermissionItems = []
    const roleId = parseInt(this.state.roleCode)
    const catIds = `${this.state.selectedCategoryList.map(this.getItemData)}`
    const deptIds = `${this.state.selectedDepartmentList.map(this.getItemData)}`
    newPermissionItems.push({
      targetCampusId: this.state.targetUcsbCampusId,
      targetNetId: this.state.targetNetId,
      departmentId: deptIds,
      categoryId: catIds,
      roleId: roleId,
      reasonData: this.state.reasonInput,
      targetUserEmail: this.state.targetUserMail,
      lastName: this.state.targetLastName,
      firstName: this.state.targetFirstName
    })
    await ApiServiceCall.createNewPermission(newPermissionItems[0]).then(
      findresponse => {
        let isError = false
        const responseArray = findresponse.listError
        const messageElement = (
          <ul className="list-group">
            {responseArray.map(item => {
              const keyId = `code-${item.categoryId}-${item.departmentId}`
              const checkedCategory = this.state.selectedCategoryList
              const checkedDepartment = this.state.selectedDepartmentList
              const departmentId = parseInt(item.departmentId)
              const categoryId = parseInt(item.categoryId)
              const categoryName = checkedCategory.filter(
                x => x.controlId === categoryId
              )[0].controlName
              const departmentName = checkedDepartment.filter(
                x => x.controlId === departmentId
              )[0].controlName
              if (item.statusCode === 2627) {
                isError = true
                return (
                  <li
                    key={keyId}
                    className="list-group-item list-group-item-warning"
                  >
                    <label>
                      An existing record exist for: <br />
                      Data Category: {categoryName} <br />
                      Role: {departmentName}
                    </label>
                  </li>
                )
              } else if (item.statusCode === 50000) {
                isError = true
                return (
                  <li
                    key={keyId}
                    className="list-group-item list-group-item-info"
                  >
                    <label>
                      NoAccess for-(category:{categoryName} and department:
                      {departmentName})
                    </label>
                  </li>
                )
              }
            })}
          </ul>
        )
        if (isError === true) {
          this.setState({
            isErrorDialogOpen: true,
            dialogMessage: messageElement
          })
        } else {
          this.props.history.push('/dashboard')
        }
      }
    )
  }

  getItemData(value) {
    return value.controlId
  }

  async fetchDepartment() {
    await ApiServiceCall.fetchDepartment().then(response => {
      const data = response
      this.setState({
        fullDepartmentList: data,
        loading: false
      })
    })
    this.setDepartmentList(this.state.roleCode)
  }

  setDepartmentList(selectedRoleCode) {
    let filterDepartment = []
    const map = new Map()
    const userAccesType = this.props.userAccesType
    const roleDetails = this.state.rolesDetails.listRoleByCustId

    if (userAccesType === 2 && selectedRoleCode === 3) {
      filterDepartment = this.state.fullDepartmentList.filter(
        opt => opt.controlId > 1
      )
    } else {
      for (const item of roleDetails) {
        if (!map.has(item.deptId)) {
          map.set(item.deptId, true) // set any value to Map
          filterDepartment.push({
            controlId: item.deptId,
            controlName: item.department
          })
        }
      }
    }

    var departmentId = []
    if (parseInt(selectedRoleCode) === 2 || parseInt(selectedRoleCode) === 1) {
      departmentId.push({
        controlId: -1,
        controlName: 'All Departments'
      })
      this.setState({
        selectedDepartmentList: departmentId
      })
    }
    this.setState({
      leftdepartmentList: filterDepartment
    })
  }
}

export default FindPeople

// Define PropType Variable
FindPeople.propTypes = {
  history: PropTypes.object.isRequired,
  userAccesType: PropTypes.number.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      roleData: PropTypes.shape({
        listRoleByCustId: PropTypes.arrayOf(
          PropTypes.shape({
            category: PropTypes.string.isRequired,
            categoryId: PropTypes.number.isRequired,
            createDateTime: PropTypes.string,
            department: PropTypes.string.isRequired,
            deptId: PropTypes.number.isRequired,
            displayFullName: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            roleId: PropTypes.number.isRequired,
            roleName: PropTypes.string,
            rowId: PropTypes.number,
            sourseCustId: PropTypes.number,
            targetCustId: PropTypes.number,
            userName: PropTypes.string
          }).isRequired
        ).isRequired
      }).isRequired
    }).isRequired
  }).isRequired
}
