import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles(theme => ({
  listSubHeader: {
    backgroundColor: 'darkgrey'
  },
  listItem: {
    backgroundColor: '#e6e6df'
  }
}))

function PeopleSearchList({ searchTerm, people, onPersonClick }) {
  const classes = useStyles()

  return (
    <List component="div">
      <ListSubheader component="div" className={classes.listSubHeader}>
        Search Results for &quot;{searchTerm}&quot;
      </ListSubheader>
      {people.map(person => {
        const {
          firstName,
          lastName,
          email,
          netId,
          ucsbCampusId,
          department
        } = person
        const name = `${firstName} ${lastName} ${
          department ? '(' + department + ')' : null
        }`
        return (
          <React.Fragment key={ucsbCampusId}>
            <ListItem
              className={classes.listItem}
              button
              disabled={!netId}
              onClick={() => {
                onPersonClick(ucsbCampusId)
              }}
            >
              <ListItemText
                data-testid="searchContainer"
                primary={name}
                secondary={email}
              />
              <ListItemSecondaryAction>
                {!netId ? (
                  <Chip label="no UCSBNetId" disabled variant="outlined" />
                ) : null}
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>
        )
      })}
    </List>
  )
}

PeopleSearchList.propTypes = {
  searchTerm: PropTypes.string,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      netId: PropTypes.string,
      ucsbCampusId: PropTypes.string,
      department: PropTypes.string
    })
  ),
  onPersonClick: PropTypes.func
}

export default PeopleSearchList
