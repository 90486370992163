import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import NavMenu from './NavMenu'
import Footer from './Footer'

export default class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <NavMenu userName={this.props.userName} isAuth="Y" />
        <div style={{ padding: 10, paddingBottom: 80 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {this.props.children}
            </Grid>
          </Grid>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

//  Define PropType Variable
Layout.propTypes = {
  userName: PropTypes.string.isRequired,
  userAccesType: PropTypes.number,
  userRoleName: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element).isRequired
}
