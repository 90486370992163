// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Main__LoginButton___1OzX3 {\r\n  margin-top: 350px;\r\n  padding: 20px;\r\n}\r\n", "",{"version":3,"sources":["Main.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;AACf","file":"Main.css","sourcesContent":[".LoginButton {\r\n  margin-top: 350px;\r\n  padding: 20px;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"LoginButton": "Main__LoginButton___1OzX3"
};
module.exports = exports;
