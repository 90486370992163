import React from 'react'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: '#9CBEBE',
    color: '#3D4952'
  }
}))

function SelectedUser({ user }) {
  const { firstName, lastName, email, department } = user
  const classes = useStyles()

  const name = `${firstName} ${lastName} ${
    department ? '(' + department + ')' : ''
  }`
  return (
    <List className={classes.list}>
      <ListItem alignItems="flex-start">
        <ListItemText primary={name} secondary={email} />
      </ListItem>
    </List>
  )
}

SelectedUser.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    netId: PropTypes.string,
    ucsbCampusId: PropTypes.string,
    department: PropTypes.string
  }).isRequired
}

export default SelectedUser
