import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

import SelectedUser from './SelectedUser'

import PeopleSearchList from './PeopleSearchList'
import ApiServiceCall from '../../Service/ApiServiceCall'

export class SearchPeople extends Component {
  constructor(props) {
    super(props)
    // Set initial State
    this.state = {
      searchInput: '',
      // Data that will be rendered in the autocomplete
      // As it is asynchronous, it is initially empty
      peopleSearchResults: [],
      showPeopleSearchList: false,
      selectedPerson: null,
      appError: false
    }
  }

  onSearchInputChange = async e => {
    const value = e.target.value
    this.setState({
      searchInput: value,
      showPeopleSearchList: true,
      selectedPerson: null
    })
    if (value) await this.fetchPeople(value)

    // clear selected target user
    this.props.onSearchPeople('')
  }

  handlePersonClick = ucsbCampusId => {
    const selectedPerson = this.state.peopleSearchResults.find(
      person => person.ucsbCampusId === ucsbCampusId
    )

    this.setState({
      showPeopleSearchList: false,
      selectedPerson
    })
    const {
      netId,
      ucsbCampusId: selectedUcsbCampusId,
      email,
      firstName,
      lastName
    } = selectedPerson

    this.props.onSearchPeople(
      netId,
      selectedUcsbCampusId,
      email,
      firstName,
      lastName,
      false
    )
  }

  render() {
    const {
      showPeopleSearchList,
      searchInput,
      peopleSearchResults
    } = this.state
    let optionList
    if (showPeopleSearchList && searchInput) {
      if (peopleSearchResults.length > 0) {
        optionList = (
          <PeopleSearchList
            searchTerm={searchInput}
            people={peopleSearchResults}
            onPersonClick={this.handlePersonClick}
          />
        )
      } else {
        optionList = (
          <div className="no-options">
            <em>search returned zero results</em>
          </div>
        )
      }
    }

    return (
      <React.Fragment>
        <TextField
          style={{ minWidth: '100%' }}
          label="Search for a Person"
          type="search"
          margin="normal"
          value={this.state.searchInput}
          onChange={this.onSearchInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {optionList}
        {this.state.selectedPerson ? (
          <SelectedUser user={this.state.selectedPerson} />
        ) : null}
      </React.Fragment>
    )
  }

  async fetchPeople(searchInput) {
    await ApiServiceCall.fetchCampusUsers(searchInput).then(response => {
      const data = response
      this.setState({
        peopleSearchResults: data
      })
    })
  }
}
// Define PropType Variable
SearchPeople.propTypes = {
  onSearchPeople: PropTypes.func.isRequired
}
export default SearchPeople
