import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto'
  },
  paper: {
    width: 147,
    height: 230,
    overflow: 'auto'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  uiStyle: {
    listStyleType: 'none',
    paddingLeft: '5px'
  }
}))

function not(arrayLeft, arrayRight) {
  return arrayLeft.filter(value => arrayRight.indexOf(value) === -1)
}

function intersection(arrayLeft, arrayRight) {
  return arrayLeft.filter(value => arrayRight.indexOf(value) !== -1)
}

function DepartmentComponent({
  leftListItems,
  rightListItems,
  onTranferClickItems
}) {
  const classes = useStyles()
  const [checked, setChecked] = React.useState([])
  const [left, setLeft] = React.useState(leftListItems)
  const [right, setRight] = React.useState(rightListItems)
  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)
  React.useEffect(() => {
    setLeft(leftListItems)
  }, [leftListItems])

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
    onTranferClickItems(leftChecked)
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
    onTranferClickItems(rightChecked)
  }

  // We are not using material list Due to Slow Performance rendring issue.
  const customList = itemsList => (
    <Paper className={classes.paper}>
      <ul className={classes.uiStyle}>
        {itemsList.map(item => {
          const keyId = `dept-${item.controlId}`
          return (
            <li key={keyId}>
              <label className="container">
                <input
                  type="checkbox"
                  tabIndex={-1}
                  checked={checked.indexOf(item) !== -1}
                  onChange={handleToggle(item)}
                />
                <span className="checkmark"></span>
                {item.controlName}
              </label>
            </li>
          )
        })}
      </ul>
    </Paper>
  )

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item data-testid="left">
        {customList(left)}
      </Grid>
      <Grid item style={{ width: '70px' }}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item data-testid="right">
        {customList(right)}
      </Grid>
    </Grid>
  )
}
// Define PropType Variable
DepartmentComponent.propTypes = {
  leftListItems: PropTypes.arrayOf(
    PropTypes.shape({
      controlId: PropTypes.number,
      controlName: PropTypes.string
    })
  ).isRequired,
  rightListItems: PropTypes.arrayOf(
    PropTypes.shape({
      controlId: PropTypes.number,
      controlName: PropTypes.string
    })
  ),
  onTranferClickItems: PropTypes.func.isRequired
}
export default memo(DepartmentComponent)
