import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import Layout from './Components/Share/Layout'
import Landing from './Components/Landing/Landing'
import Dashboard from './Components/Dashboard/Dashboard'
import FindPeople from './Components/FindPeople/FindPeople'
import LogOut from './Components/Share/LogOut'
import AppError from './Components/Share/AppError'

import './App.css'
import ApiServiceCall from './Service/ApiServiceCall'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      userDetails: {}
    }
  }

  componentDidMount() {
    this.populateUserData()
  }

  render() {
    const { error, isLoaded } = this.state
    if (error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div>Loading...</div>
    } else {
      return (
        <div className="App">
          <Layout
            userName={this.state.userDetails.userName}
            userAccesType={this.state.userDetails.userAccesType}
            userRoleName={this.state.userDetails.userRoleName}
          >
            <Route exact path="/" component={Landing} />
            <Route
              path="/dashboard"
              render={props => (
                <Dashboard
                  {...props}
                  userAccesType={this.state.userDetails.userAccesType}
                />
              )}
            />
            <Route
              path="/find-people"
              render={props => (
                <FindPeople
                  {...props}
                  userAccesType={this.state.userDetails.userAccesType}
                />
              )}
            />
            <Route path="/log-out" component={LogOut} />
            <Route path="/error" component={AppError} />
          </Layout>
        </div>
      )
    }
  }

  async populateUserData() {
    await ApiServiceCall.fetchUser().then(response => {
      this.setState({
        isLoaded: true,
        userDetails: response
      })
    })
  }
}

export default App
