import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import ucsbLogoSrc from '../../assets/UCSB_Tab_Navy_RGB.png'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}))

export default function NavMenu(props) {
  const classes = useStyles()

  const { isAuth, userName } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderAvatarControls = () => {
    if (isAuth === 'Y')
      return (
        <div>
          {userName}
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} component={Link} to="/">
              Home
            </MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/dashboard">
              Manage Permissions
            </MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/log-out">
              Logout
            </MenuItem>
          </Menu>
        </div>
      )
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: '#003660' }}>
        <Toolbar>
          <a href="https://www.ucsb.edu">
            <img src={ucsbLogoSrc} width="100" />
          </a>
          <Typography
            variant="h6"
            className={classes.title}
            style={{ textAlign: 'center' }}
          >
            LightHouse
          </Typography>
          {renderAvatarControls()}
        </Toolbar>
      </AppBar>
    </div>
  )
}
// Define PropType Variable
NavMenu.propTypes = {
  userName: PropTypes.string.isRequired,
  isAuth: PropTypes.string.isRequired
}
