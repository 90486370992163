import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import ApiServiceCall from '../../Service/ApiServiceCall'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto'
  },
  paper: {
    width: 400,
    height: 230,
    overflow: 'auto'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  uiStyle: {
    listStyleType: 'none',
    paddingLeft: '5px'
  }
}))
function getNestedChildren(arr, newChecked) {
  newChecked.push(arr)
  if (arr.children != null) {
    for (let i = 0; i < arr.children.length; i++) {
      getNestedChildren(arr.children[i], newChecked)
    }
  }
}
function removeNestedChildren(currentIndex, newChecked, arrValue) {
  if (currentIndex >= 0) {
    newChecked.splice(currentIndex, 1)
    if (arrValue.children != null) {
      for (let i = 0; i < arrValue.children.length; i++) {
        const currentIndex = newChecked.indexOf(arrValue.children[i])
        removeNestedChildren(currentIndex, newChecked, arrValue.children[i])
      }
    }
  }
}

function CategoryComponent({ userRole, onCategoryCheckChange }) {
  const classes = useStyles()
  const [checked, setChecked] = React.useState([])
  const [left, setLeft] = React.useState([])

  React.useEffect(() => {
    getCategory()
  }, [userRole])

  const getCategory = async () => {
    const roleId = userRole
    await ApiServiceCall.fetchCategory(roleId).then(response => {
      const data = response
      setLeft(data)
      setChecked([])
    })
  }
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)

    const newChecked = [...checked]
    if (currentIndex === -1) {
      getNestedChildren(value, newChecked)
    } else {
      const arrValue = newChecked[currentIndex]
      removeNestedChildren(currentIndex, newChecked, arrValue)
    }

    newChecked.sort(function(a, b) {
      return a.sortOrder - b.sortOrder
    })

    const innerArrayChecked = [...newChecked]
    const outerArrayChecked = [...newChecked]
    var newArrayChecked = []

    for (let i = 0; i < outerArrayChecked.length; i++) {
      outerArrayChecked[i].status = 'P'
      for (let j = 0; j < innerArrayChecked.length; j++) {
        if (outerArrayChecked[i].treeId === innerArrayChecked[j].parentId) {
          newArrayChecked.push({
            categoryId: innerArrayChecked[j].treeId,
            status: 'L'
          })
        }
      }
    }
    for (let i = 0; i < outerArrayChecked.length; i++) {
      for (let j = 0; j < newArrayChecked.length; j++) {
        if (outerArrayChecked[i].treeId === newArrayChecked[j].categoryId) {
          outerArrayChecked[i].status = 'L'
        }
      }
    }

    var newArray = outerArrayChecked.filter(function(item) {
      return item.status === 'P'
    })
    setChecked(newChecked)

    onCategoryCheckChange(newArray)
  }

  const customList = children => (
    <React.Fragment>
      {children.map(items => {
        const keyId = `cat-${items.treeId}`
        const treelevel = items.level
        const counter = 0.5
        var mrgLeft = ''
        mrgLeft = `${counter * treelevel + (treelevel - 1)}em`
        return (
          <React.Fragment key={keyId}>
            <li key={keyId} style={{ marginLeft: mrgLeft }}>
              <label className="container">
                <input
                  type="checkbox"
                  tabIndex={-1}
                  checked={checked.indexOf(items) !== -1}
                  onChange={handleToggle(items)}
                />
                <span className="checkmark"></span>
                {items.treeName}
              </label>
            </li>
            {items.children != null && customList(items.children)}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <Paper className={classes.paper}>
          <ul className={classes.uiStyle}>{customList(left)}</ul>
        </Paper>
      </Grid>
    </Grid>
  )
}
// Define PropType Variable
CategoryComponent.propTypes = {
  userRole: PropTypes.number.isRequired,
  onCategoryCheckChange: PropTypes.func.isRequired
}
export default memo(CategoryComponent)
