import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import MaterialTable from 'material-table'
import Icon from '@material-ui/core/Icon'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import ApiServiceCall from '../../Service/ApiServiceCall'

import './Dashboard.css'

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      permissionDetails: [],
      loading: true,
      isDialogOpen: false,
      deletedRowData: [],
      isPermisssionDialog: false,
      pageSizeGrid: 5,
      pageOptionGrid: []
    }
  }

  onDialogOK = async e => {
    const _rowData = this.state.deletedRowData

    await ApiServiceCall.deletePermission(_rowData.rowId).then(res => {
      if (res.status === 200) {
        this.setState({
          isDialogOpen: false,
          isPermisssionDialog: false
        })
        this.populateUserPermissionData()
      } else {
        alert(
          'You are unable to delete a permission for the target customer..!'
        )
      }
    })
  }

  onDialogCancel = e => {
    this.setState({
      isDialogOpen: false,
      isPermissionDelete: true
    })
  }

  onAddNewPermissionClickHandler = () => {
    this.props.history.push({
      pathname: '/find-people',
      state: {
        roleData: this.state.permissionDetails,
        userAccesType: this.props.userAccesType
      }
    })
  }

  componentDidMount() {
    const optionGrid = []
    var winHeight = parseInt(screen.height)
    // console.log(`winHeight: ${winHeight}`)
    const pageSize = Math.floor(winHeight / 100)
    // console.log(`Page Size: ${pageSize}`)
    for (let i = 1; i <= 5; i++) {
      const counter = pageSize * i
      optionGrid.push(counter)
    }
    this.setState({
      pageSizeGrid: pageSize,
      pageOptionGrid: optionGrid
    })

    this.populateUserPermissionData()
  }

  render() {
    // debugger;
    // if (document.querySelector(".del-ico") != null) {

    // }
    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiButton: {
          // Name of the rule
          text: {
            // Some CSS
            background: 'linear-gradient(45deg, #C9BF9D 30%, #C9BF9D 90%)',
            borderRadius: 3,
            border: 0,
            color: '#003660',
            fontWeight: 'bold',
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)'
          }
        },
        MuiIconButton: {
          // Name of the rule
          root: {
            color: 'red'
          }
        }
      }
    })

    const buttonDialogStyle = {
      color: 'white',
      backgroundColor: 'darkslateblue'
    }
    let gridUserPermission

    if (this.state.loading) {
      gridUserPermission = (
        <p>
          <em>Loading...</em>
        </p>
      )
    } else {
      const userData = this.state.permissionDetails
      const userPermissionList = userData.listPermissionByCustId
      let addNewUserButton = ''
      if (this.props.userAccesType !== 4) {
        addNewUserButton = (
          <ThemeProvider theme={theme}>
            <Button onClick={this.onAddNewPermissionClickHandler}>
              Add New User/Permission
            </Button>
          </ThemeProvider>
        )
      }

      gridUserPermission = (
        <MaterialTable
          style={{ padding: 0 }}
          title={addNewUserButton}
          columns={[
            {
              title: 'NetId',
              field: 'userName',
              searchable: true,
              defaultGroupOrder: 0
            },
            {
              title: 'Name',
              field: 'displayFullName',
              searchable: true,
              grouping: true
            },
            {
              title: 'Category',
              field: 'category',
              searchable: true,
              grouping: true
            },
            {
              title: 'Department',
              field: 'department',
              searchable: true,
              grouping: true
            },
            {
              title: 'Created',
              field: 'createDateTime',
              searchable: true,
              grouping: false
            },
            {
              title: 'FirstName',
              field: 'firstName',
              hidden: true,
              searchable: true
            },
            {
              title: 'LastName',
              field: 'lastName',
              hidden: true,
              searchable: true
            }
          ]}
          data={userPermissionList}
          options={{
            headerStyle: {
              backgroundColor: '#ebf0f3',
              color: '#222',
              padding: '2px'
            },
            rowStyle: {
              padding: '0px'
            },
            // selection: true,
            actionsColumnIndex: -1,
            grouping: true,
            pageSize: this.state.pageSizeGrid,
            pageSizeOptions: this.state.pageOptionGrid
          }}
          actions={[
            {
              icon: props => (
                <Icon className="del-ico" style={{ color: 'red' }}>
                  delete
                </Icon>
              ),
              tooltip: 'Delete User Role',
              onClick: (event, rowData) => {
                this.setState({
                  isDialogOpen: true,
                  deletedRowData: rowData,
                  isPermissionDelete: true
                })
              }
            }
          ]}
        />
      )
    }

    const dialogUserPermission = (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={this.state.isDialogOpen}
        keepMounted
      >
        <DialogTitle className="dialog-title" id="confirmation-dialog-title">
          Delete Permission
        </DialogTitle>
        <DialogContent className="dialog-content" dividers>
          Do You want to delete the Permission?.
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button
            autoFocus
            style={buttonDialogStyle}
            onClick={this.onDialogCancel}
          >
            Cancel
          </Button>
          <Button style={buttonDialogStyle} onClick={this.onDialogOK}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )

    return (
      <div id="divGrid">
        {gridUserPermission}
        {dialogUserPermission}
      </div>
    )
  }

  async populateUserPermissionData() {
    await ApiServiceCall.fetchRoleAndPermission().then(response => {
      const data = response
      this.setState({
        permissionDetails: data,
        loading: false
      })
    })
  }
}
//  Define PropType Variable
Dashboard.propTypes = {
  userAccesType: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired
}
