// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "body {\r\n}\r\n.Dashboard__oval___1bOPV {\r\n  width: 150px;\r\n  height: 25px;\r\n  background: #d6dfe6;\r\n  border-radius: 80px / 60px;\r\n  text-align: center;\r\n  padding-top: 4px;\r\n  border: 1px solid #c0c2c3;\r\n}\r\n.Dashboard__middle___19SBE > * {\r\n  vertical-align: middle;\r\n}\r\n.Dashboard__MuiIconButton-root___38jU5 {\r\n  padding: 2px !important;\r\n}\r\n.Dashboard__MuiTableCell-root___3OMRH {\r\n  padding: 2px !important;\r\n}\r\n", "",{"version":3,"sources":["Dashboard.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B;AACA;EACE,sBAAsB;AACxB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB","file":"Dashboard.css","sourcesContent":["body {\r\n}\r\n.oval {\r\n  width: 150px;\r\n  height: 25px;\r\n  background: #d6dfe6;\r\n  border-radius: 80px / 60px;\r\n  text-align: center;\r\n  padding-top: 4px;\r\n  border: 1px solid #c0c2c3;\r\n}\r\n.middle > * {\r\n  vertical-align: middle;\r\n}\r\n.MuiIconButton-root {\r\n  padding: 2px !important;\r\n}\r\n.MuiTableCell-root {\r\n  padding: 2px !important;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"oval": "Dashboard__oval___1bOPV",
	"middle": "Dashboard__middle___19SBE",
	"MuiIconButton-root": "Dashboard__MuiIconButton-root___38jU5",
	"MuiTableCell-root": "Dashboard__MuiTableCell-root___3OMRH"
};
module.exports = exports;
