import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import ApiServiceCall from '../../Service/ApiServiceCall'

class RoleComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      roleList: [],
      loading: false,
      selectedRole: 2
    }
  }

  componentDidMount() {
    this.populateRoleData()
    const roleId = this.props.userAccesType
    this.props.onGetRole({ roleId })
  }

  onChange = e => {
    const roleId = e.target.value
    this.setState({
      selectedRole: roleId
    })
    this.props.onGetRole({ roleId })
  }

  render() {
    return (
      <div>
        <InputLabel>Select an Access Level</InputLabel>
        <Select
          data-testid="ddlRole"
          value={this.state.selectedRole}
          onChange={this.onChange}
          style={{ width: '100%' }}
        >
          {this.state.roleList.map(item => {
            const keyId = `role-${item.controlId}`
            return (
              <MenuItem key={keyId} value={item.controlId}>
                {item.controlName}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    )
  }

  async populateRoleData() {
    const roleId = this.props.userAccesType
    await ApiServiceCall.fetchRole().then(response => {
      const data = response
      const filterRoleList = data.filter(
        optionRole => optionRole.controlId >= roleId
      )
      this.setState({
        roleList: filterRoleList,
        selectedRole: filterRoleList[0].controlId,
        loading: false
      })
    })
  }
}
// Define PropType Variable
RoleComponent.propTypes = {
  userAccesType: PropTypes.number.isRequired,
  onGetRole: PropTypes.func.isRequired
}

export default RoleComponent
