import React, { Component } from 'react'

import Button from '@material-ui/core/Button'

import NavMenu from './Components/Share/NavMenu'
import App from './App'
import ApiServiceCall from './Service/ApiServiceCall'

import './Main.css'
import lightHouseImageSrc from './assets/lightHouse.jpg'

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isAuthenticated: false
    }
  }

  componentDidMount() {
    this.UserAuthenticated()
  }

  loginClickHandler() {
    window.location.href = 'Home/Landing'
  }

  render() {
    const buttonStyle = {
      color: '#003660',
      backgroundColor: '#C9BF9D',
      fontWeight: 'bold'
    }

    if (this.state.isAuthenticated) {
      return <App />
    } else {
      return (
        <React.Fragment>
          <NavMenu userName="" isAuth="N" />
          <div
            style={{
              backgroundImage: `url(${lightHouseImageSrc})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '700px'
            }}
          >
            <h2 style={{ margin: 0, padding: '20px' }}>
              <p>
                LightHouse manages user and access roles to the
                <br />
                next-generation Data Warehouse.
              </p>
            </h2>
            <div className="LoginButton">
              <Button
                variant="contained"
                style={buttonStyle}
                onClick={this.loginClickHandler}
              >
                Login
              </Button>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  async UserAuthenticated() {
    await ApiServiceCall.fetchAuthentication().then(response => {
      const isUserAuthenticated = response.result
      let isSecure = false
      if (isUserAuthenticated === 'Y') {
        isSecure = true
      }
      this.setState({
        isAuthenticated: isSecure
      })
    })
  }
}

export default Main
