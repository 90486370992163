import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import Button from '@material-ui/core/Button'

import markdownMessage from '../../assets/whatIsLightHouse.md'

class Landing extends Component {
  onManagePermissionsClickHandler = () => {
    this.props.history.push({
      pathname: '/dashboard'
    })
  }

  render() {
    const buttonStyle = {
      color: '#003660',
      backgroundColor: '#C9BF9D',
      fontWeight: 'bold'
    }
    return (
      <React.Fragment>
        <Button
          variant="contained"
          style={buttonStyle}
          onClick={this.onManagePermissionsClickHandler}
        >
          Manage Permissions
        </Button>
        <ReactMarkdown source={markdownMessage} />
      </React.Fragment>
    )
  }
}
Landing.propTypes = {
  history: PropTypes.object.isRequired
}
export default Landing
