import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(_theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'rgb(0, 54, 96)',
    color: 'white',
    padding: '10px'
  }
}))

export default function Footer() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Container maxWidth="md">
          <Typography
            variant="body1"
            color="inherit"
            style={{ textAlign: 'center' }}
          >
            Copyright © {new Date().getFullYear()} The Regents of the University
            of California
          </Typography>
        </Container>
      </AppBar>
    </React.Fragment>
  )
}
