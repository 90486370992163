const accessManageRoleUrl = `api/ManageRole`
const accessCategoryUrl = `api/Category`
const accessDepartmentUrl = `api/Department`
const accessDirectoryUserUrl = `api/directory`
const accessRoleUrl = `api/Role`
const accessUserUrl = `api/User`
const accessAutUrl = `api/Authenticate`

export default {
  // Define All Get Call
  fetchAuthentication: () => {
    return getServiceCall(accessAutUrl)
  },
  fetchUser: () => {
    return getServiceCall(accessUserUrl)
  },
  fetchRoleAndPermission: () => {
    return getServiceCall(accessManageRoleUrl)
  },

  fetchRole: () => {
    return getServiceCall(accessRoleUrl)
  },

  fetchCategory: roleId => {
    return getServiceCall(`${accessCategoryUrl}?roleId=${roleId}`)
  },

  fetchDepartment: () => {
    return getServiceCall(accessDepartmentUrl)
  },

  fetchCampusUsers: searchInput => {
    return getServiceCall(
      `${accessDirectoryUserUrl}?pattern_data=${searchInput}`
    )
  },

  // Get Call Close

  // Define All Post Call for Apllication
  async createNewPermission(postItems) {
    const responseResult = await postServiceCall(postItems, accessManageRoleUrl)
    return responseResult
  },
  // Post Call Close

  // Define All Delete Calls for Application
  async deletePermission(permissionId) {
    return await deleteServiceCall(
      `${accessManageRoleUrl}?rowId=${permissionId}`
    )
  }
}
function handleResponseError(response) {
  console.log('HTTP error, status = ' + response.status)
  return response
}
function handleError(error) {
  console.log(error.message)
}
async function getServiceCall(fetchURL) {
  return fetch(fetchURL)
    .then(response => {
      if (!response.ok) {
        handleResponseError(response)
      }
      return response.json()
    })
    .catch(error => {
      handleError(error)
    })
}
async function postServiceCall(postItems, postURL) {
  return fetch(postURL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postItems)
  })
    .then(response => {
      if (!response.ok) {
        handleResponseError(response)
      }
      return response.json()
    })
    .catch(error => {
      handleError(error)
    })
}
async function deleteServiceCall(deletedItemsURL) {
  return fetch(deletedItemsURL, {
    method: 'DELETE',
    mode: 'cors'
  })
    .then(response => {
      if (!response.ok) {
        handleResponseError(response)
      }
      return response
    })
    .catch(error => {
      handleError(error)
    })
}
